<template lang="pug">
  span
    slot(:update="courseDayResultsEmail")
      v-checkbox(
        v-model="item.result.send_result_emails"
        :key="item.exam.id"
        @change="courseDayResultsEmail"
        hide-details
        color="green"
        :disabled="disabled"
      ).mt-0
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import ResultsService from '@/app/admin/modules/results/core/results-service'

export default {
  mixins: [errorsMixin],

  props: {
    disabled: Boolean,

    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    examResultsService: new ResultsService()
  }),

  methods: {
    async courseDayResultsEmail(val) {
      try {
        await this.examResultsService.updateSendResults(this.item.exam.id, val);
        this.$notify({type: 'success', text: 'Updated'});
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  }
}
</script>
